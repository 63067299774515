<template>
    <div id="real_time">
        <top-msg :formData="formData" :detail="topDetail"></top-msg>
        <div class="content">
            <div class="item_chart">
                <div class="item">
                        <div class="item_title"><span >车辆间隔信息</span></div>
                        <div class="items">
                            <span class="item_content" v-for="item in largeIntervalList" :key="item.vehicleNo1+':'+item.vehicleNo2">
                                <img src="@/assets/realTime/car.png"><span> {{item.vehicleNo1}} </span><i class="el-icon-minus"></i><span> {{item.vehicleNo2}} </span><span style="color:#FF0000;"> (间隔 {{item.realSpace}}站)  </span>
                            </span>
                        </div>
                </div>
                <el-divider></el-divider>
                <div class="item">
                    <div class="item_title"><span >车辆串车信息</span></div>
                    <div class="items">
                            <span class="item_content" v-for="item in stringCarList" :key="item.vehicleNo1+':'+item.vehicleNo2">
                            <img src="@/assets/realTime/car.png"><span>{{item.vehicleNo1}} </span><i class="el-icon-minus"></i><span> {{item.vehicleNo2}} </span><span style="color:#FF0000;"> (间隔 {{item.realSpace}}站)  </span>
                        </span>
                    </div>
                </div>
            </div>
            <real-run :formData="formData" :waitVehicleList="waitVehicleList"></real-run>
        </div>
    </div>
</template>
<script>
import realRun from './realRunMainSub/realRun.vue'
import topMsg from './realRunMainSub/topMsg.vue'
export default{
    data(){
        return{
            topDetail:{},
            waitVehicleList:[],
            LargeIntervalDetail: '',
            stringCarTimesDetail: '',
            largeIntervalList: [],
            stringCarList: [],
            largeIntervalDetailList:[],
            stringCarDetail:[]
        }
    },
    provide() {
        return {
            largeIntervalDetailList: this.largeIntervalDetailList,
            stringCarDetail: this.stringCarDetail
        };
    },
    props:{
        formData:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        realRun,
        topMsg
    },
    methods:{
        getData(){
            let url = '/waybillActual/queryRealtimeWayBill'
            let params = {
                ...this.formData
            }
            this.$http.post(url,params).then(({detail})=>{
                console.log(detail)
                this.topDetail = detail
            })
        },
        getWaitVehicleList(){
            let url = '/waybillActual/queryWaitVehicleList'
            let params = {
                ...this.formData
            }
            this.$http.post(url,params).then(({detail})=>{
                this.waitVehicleList.splice(0)
                this.waitVehicleList.push(...detail)
                console.log(this.waitVehicleList)
            })
        },
        getException(){
            let url = '/home/getException'
            this.$http.post(url).then(({detail})=>{
                let option = detail
                this.topDetail = {
                    ...this.topDetail,
                    ...option}
            })
        },
        getLargeIntervalDetail() {
            let url = '/home/getLargeIntervalDetail'
            this.$http.post(url).then(({detail})=>{
                this.largeIntervalList = []
                this.largeIntervalDetailList.splice(0);
                if(detail && detail.length) {
                    detail.map(item=>{
                        if(this.formData.routeId == item.routeId) {
                            this.largeIntervalList.push(item)
                            this.largeIntervalDetailList.push(item)
                        }
                    })
                }
                console.log(this.largeIntervalDetailList)
            })
        },
        getStringCarDetail() {
            let url = '/home/getStringCarDetail'
            this.$http.post(url).then(({detail})=>{
                this.stringCarList = []
                this.stringCarDetail.splice(0);
                if(detail && detail.length) {
                    detail.map(item=>{
                        if(this.formData.routeId == item.routeId) {
                            this.stringCarDetail.push(item)
                            this.stringCarList.push(item)
                        }
                    })
                }
                console.log(this.stringCarList)
            })
        }
    }
}
</script>
<style lang="scss" scope="real_time">
.real_time {
    width: 100%;
    min-width: 1200px;
    .content {
        border: 1px solid $bordercolor;
        padding: 10px;
        .item_chart {
                border: 1px solid $bordercolor;
                .item {
                    display: flex;

                    .item_title {
                        text-align: center;
                        padding-top: 15px;
                        display: inline-block;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 18px;
                        font-weight: bold;
                        color: #575757;
                        line-height: 36px;
                        min-width: 160px;
                        min-height: 60px;
                        background-color: #E6E6E6;
                    }
                    .items {
                        padding:10px;
                        display: inline-block;
                    }
                    .item_content {
                        margin-left: 20px;
                        font-size: 16px;
                    }
                }
            }
    }
    .el-divider--horizontal {
        margin: 1px 0;
    }
}
</style>