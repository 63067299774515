<template>
    <div class="real_time_site" :class="{'type_down':type == 1}">
        <div class="site_name" v-if="type==0">{{siteName}}</div>
        <div class="vehicles" v-if="type==1">
            <div class="ve" v-for="(one,k) in vehicleList" :key="k">
                <span>{{one.customNo}}</span>
                <img :src="one.src" />
            </div>
        </div>
        <el-popover :placement="type==1?'top':'bottom'" trigger="hover" :disabled="vehicleList.length<=1">
            <ul class="pop_ul">
                <li class="pop_li" v-for="(one,kk) in vehicleList" :key="kk">
                    <div class="point">
                        <div class="mask out" :class="{'in':one.isOnline == '1'}"></div>
                    </div>
                    <img :src="one.src" />
                    <span>
                        {{one.customNo}}
                    </span>
                </li>
            </ul>
            <div class="point" slot="reference">
                <!-- {{num}} -->
                <div class="mask" :class="mainMaskStyle">{{num}}</div>
            </div>
        </el-popover>
        <div class="vehicles" v-if="type==0">
            <div class="ve" v-for="(one,k) in vehicleList" :key="k">
                <span>{{one.customNo}}</span>
                <img :src="one.src" />
            </div>
        </div>
        <div class="site_name" v-if="type==1">{{siteName}}</div>
    </div>
</template>
<script>
import Util from '@/common/js/util.js'
import lgcar from "@/assets/realTime/lgcar.png";
import smCar from "@/assets/realTime/smCar.png";
import stringCarSite from "@/assets/realTime/stringCarSite.png";
import onSite from "@/assets/realTime/onSite.png";
import outLineCar from "@/assets/realTime/outLineCar.png";
export default {
    data() {
        return {
            lgcar,
            stringCarSite,
            onSite,
            outLineCar,
            smCar
        }
    },
    props: {
        siteName: {
            type: String,
            default: '固戍地铁站'
        },
        num: {
            type: Number,
            default: 5
        },
        type: {
            type: String,
            default: '0'
        },
        vehicleList: {
            type: Array,
            default: () => {
                return [{
                    customNo: '987674',
                    isOnline: '1'
                }, {
                    customNo: '876544',
                    isOnline: '0'
                }]
            }
        },
        largeIntervalDetailList: {
            type: Array,
            default: () => {}
        },
        stringCarDetail: {
            type: Array,
            default: () => {}
        }
    },
    computed: {
        mainMaskStyle: function() {
            let c = ''
            for (var i = this.vehicleList.length - 1; i >= 0; i--) {
                this.largeIntervalDetailList.forEach(item => {
                    if(this.vehicleList[i].vehicleNo == item.vehicleNo1 || this.vehicleList[i].vehicleNo == item.vehicleNo2) {
                        if(item.remark == "大间隔") {
                            c = 'lgs';
                        } else if(item.remark == "小间隔") {
                            c = 'sgs';
                        }
                    }
                });
                if(c != '' && (c == 'lgs' || c == 'sgs')) {
                    break;
                }
                this.stringCarDetail.forEach(item=> {
                    if(this.vehicleList[i].vehicleNo == item.vehicleNo1 || this.vehicleList[i].vehicleNo == item.vehicleNo2) {
                        c = 'stc'
                    }
                })
                if(c != '' && c== 'stc') {
                    break;
                }
                if (this.vehicleList[i].isOnline == '1') {
                    c = 'in'
                    break;
                }
                c = 'out'
            }
            return c
        }
    },
    filters: {
        vehicleNoFilter: (val) => {
            if (Util.isChinese(val)) {
                return val.substr(1)
            }
            return val
        }
    },
    watch: {
        "vehicleList": {
            handler(val) {
                let c = ''
                for (var i = val.length - 1; i >= 0; i--) {
                    let vehicle = val[i]
                    this.largeIntervalDetailList.forEach(item => {
                        if(val[i].vehicleNo == item.vehicleNo1 || val[i].vehicleNo == item.vehicleNo2) {
                            if(item.remark == "大间隔") {
                                c = 'lgs'
                                vehicle['src'] =this.lgcar
                            } else if(item.remark == "小间隔") {
                                c = 'sgs';
                                vehicle['src'] =this.smCar
                            }
                        }
                    });
                    if(c != '' && (c == 'lgs' || c == 'sgs')) {
                        break;
                    }
                    this.stringCarDetail.forEach(item=> {
                        if(val[i].vehicleNo == item.vehicleNo1 || val[i].vehicleNo == item.vehicleNo2) {
                            c = 'stc'
                            vehicle['src'] = this.stringCarSite
                        }
                    })
                    if(c != '' && c== 'stc') {
                        break;
                    }
                    if (val[i].isOnline == '1') {
                        c = 'in'
                        vehicle['src'] = this.onSite
                        break;
                    }
                    c = 'out'
                    vehicle['src'] = this.outLineCar
                }
            },
            deep:true
        }
    }
}

</script>
<style lang="scss" scoped="real_time_site">
@import '../../realTime.scss';

.real_time_site {
    width: $site_size;
}

.point {
    line-height: $site_size - 4px;
    text-align: center;
    width: $site_size;
    height: $site_size;
    border-radius: 50%;
    border: 2px solid $rbc;
    background: #fff;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    box-sizing: border-box;
    @extend .hand;

    .mask {
        position: absolute;
        left: -2px;
        //top: -2px;
        color: #B5B5B5;
        width: $site_size;
        height: $site_size;

        &.out {
            color: #fff;
            background: scale-color($rbc, $lightness: 50%);
        }

        &.in {
            color: #fff;
            background: $rbc;
        }
        &.lgs {
            color: #fff;
            background: $lgs;
        }
        &.sgs {
            color: #fff;
            background: $sgs;
        }
        &.stc {
            color: #fff;
            background: $stc;
        }

    }
}

.site_name {
    writing-mode: vertical-lr;
    letter-spacing: 2px;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 140px;
    text-align: right;
    line-height: $site_size;
    padding:4px 0;
}

.vehicles {
    height: 60px;
    transform: translateX(-7px);
    .ve{
        float: left;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
    }
}

.type_down {
    .site_name {
        text-align: left;
    }

    .vehicles {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.pop_ul {
    .pop_li {
        >* {
            vertical-align: middle;

        }

        .point {
            display: inline-block;
            width: $site_size / 1.5;
            height: $site_size / 1.5;
        }
    }
}

</style>
