<template>
    <div>
        <slot >
            <img :src="icon" class="img"/>
        </slot>
        <div class="top_msg_item">
            <div class="value">{{value}}</div>
            <div class="label">{{label}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: 'label'
        },
        value: {
            type: [String, Number],
            default: 0
        },
        icon: {
            type: String
        }
    }
}

</script>
<style lang="scss" scoped="top_msg_item">
@import '../../realTime.scss';
.img{
    height: 45px;
    padding-left: 10px;
    padding-top: 8px;
}
.top_msg_item {
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $top_item_h;
    text-align: center;

    .label {
        font-size: 15px;
        color: #B5B5B5 ;
    }

    .value {
        color: $rbc;
        font-size: 24px;
        font-weight: bold;
        color: #575757;
    }
}

</style>
