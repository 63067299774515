<template>
    <div class="top_msg">
        <div class="msg_item">
            <div class="title">{{formData.routeNa}}</div>
            <div class="date">{{formData.oDate}}</div>
        </div>
        <div class="list_box" v-for="(li,k) in dataList" :key="k" :style="`flex:${li.length}`">
            <item class="list_item" v-for="one in li" :label="one.label" :icon="one.icon" :value="detail[one.prop]" :key="one.label+k"></item>
        </div>
    </div>
</template>
<script>
import item from './topMsgSub/item.vue'
export default {
    props:{
        formData:{
            type:Object,
            default:()=>{}
        },
        detail:{
            type:Object,
            default:()=>{}
        },
    },
    data() {
        return {
            siteList:[],
            dataList: [
                [{
                    icon: require("@/assets/realTime/planShift.png"),
                    label: '计划班次数',
                    prop: 'totalShiteNum'
                }, {
                    icon: require("@/assets/realTime/noshift.png"),
                    label: '未发车班次数',
                    prop: 'noDepartureNum'
                }, {
                    icon: require("@/assets/realTime/finshShift.png"),
                    label: '已发车班次数',
                    prop: 'departureNum'
                }, {
                    icon: require("@/assets/realTime/isFinished.png"),
                    label: '已完成班次数',
                    prop: 'completedNum'
                }, {
                    icon: require("@/assets/realTime/outTime.png"),
                    label: '过时未发车班次数',
                    prop: 'outOfWDeparture'
                },{
                    icon: require("@/assets/realTime/largeInterval.png"),
                    label: '大间隔数',
                    prop: 'largeIntervalTimes'
                },{
                    icon: require("@/assets/realTime/stringCar.png"),
                    label: '串车数',
                    prop: 'stringCarTimes'
                }]
            ]
        }
    },
    components: {
        item
    },
    watch:{},
    methods:{}
}

</script>
<style lang="scss" scoped="top_msg">
@import '../realTime.scss';

.top_msg {
    border: 1px solid $bordercolor;
    margin-bottom: 22px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 15px;
    display: flex;

    .msg_item {
        display: flex;
        width: 120px;
        height: 100px !important;
        flex-direction: column;
        justify-content: center;
        height: $top_item_h;
        background-color: #E6E6E6;

        .title {
            font-weight: bold;
            font-size: 24px;
        }
        .date {
            font-size: 18px;
            margin-top: 10px;
        }
    }

    .list_box{
        display: flex;
        border-left:1px solid $bordercolor;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 20px;
        padding-left: 10px;
        .list_item {
            flex: 1;
            display: inline-flex;
        }
    }
}

</style>
