/**
 * 将时间戳转为设备时间
 * @param {number} stamp 时间戳 miliseconds
 * @returns 时间字符串yyyyMMddhhmmss
 */
export function stampToFullTime(stamp) {
  let date = new Date(stamp);
  let y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    h = date.getHours(),
    i = date.getMinutes(),
    s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  const t = "" + y + m + d + h + i + s;
  return t;
}

/**
 * 设备时间转为时间戳
 * @param {String} time mac time 例如：20210524000000
 * @returns timestamp
 */
export function macTimeToStamp(time) {
  if (time && time.length === 14) {
    // const year = `20${time.substr(0, 2)}`;
    const year = time.substr(0, 4);
    const month = time.substr(4, 2);
    const day = time.substr(6, 2);
    const hour = time.substr(8, 2);
    const minute = time.substr(10, 2);
    const second = time.substr(12, 2);
    let newTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    newTime = newTime.replace(/-/g, "/");
    return new Date(newTime).getTime();
  } else {
    return 0;
  }
}

/**
 * miniseconds 转为 时分秒
 * @param {number} val timestamp
 * @returns hh时mm分ss秒
 */
export function getTime(val) {
  const h = Math.floor(val / 1000 / (60 * 60));
  const s = Math.floor(((val / 1000) % (60 * 60)) / 60);
  const m = Math.floor(((val / 1000) % (60 * 60)) % 60);
  if (h) {
    return h + "时" + s + "分" + m + "秒";
  } else if (s) {
    return s + "分" + m + "秒";
  } else {
    return m + "秒";
  }
}

/**
 * 将hh:mm:ss转换为timestamp
 * @param {String} timeStr hh:mm:ss
 * @returns timestamp
 */
export function getStamp(timeStr) {
  if (timeStr) {
    const list = timeStr.split(":");
    return (
      (Number(list[0]) * 60 * 60 + Number(list[1] * 60) + Number(list[2])) *
      1000
    );
  } else {
    return 0;
  }
}

export function getUUID() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}
