<template>
    <div class="real_time">
        <div class="route_content">
            <!-- <div class="tabs">
                <div :class="{ alive: tab == k }" v-for="(one, k) in tabList" :key="one" v-on:click="tab = k">{{ one }}</div>
            </div> -->
            <real-run-main ref="realRunMain" v-show="tab == '0'" :formData="formData"></real-run-main>
            <real-run-map ref="realRunMap" v-show="tab == '1'" :formData="formData" :bmpId="bmpId" :busList="positionVehicleList"></real-run-map>
        </div>
    </div>
</template>

<script>
    import realRunMain from "./realRunMain.vue";
    import realRunMap from "./realRunMap.vue";
    import { getUUID} from "../videoPlayer/flvPlayer/utils";
    export default {
        data() {
            return {
                routeList: [],
                siteList: [],
                positionVehicleList: [],
                bmpId: "",
                formData: {
                    routeId: "",
                    routeNa: "",
                    oDate: new Date().Format("yyyy-MM-dd"),
                },
                // tab: 0,
                // tabList: ["站点模式", "地图模式"],

                intervalObj: null,

                isAnnular: false, //是否环形
                preTrackList: []
            };
        },
        props: {
            params: Object,
            tab: String
        },
        components: {
            realRunMap,
            realRunMain
        },
        provide() {
            return {
                siteList: this.siteList,
                positionVehicleList: this.positionVehicleList,
                isAnnular: this.getType
            };
        },
        activated() {
            if (this.formData.routeId) {
                this.setIntervalObj();
            }
        },
        beforeDestroy(){
            clearInterval(this.intervalObj);
            this.intervalObj = null;
        },
        deactivated() {
            clearInterval(this.intervalObj);
            this.intervalObj = null;
        },
        created() {
            this.bmpId = getUUID();
            const { routeId, routeNa, isAnnular } = this.params;
            this.formData.routeId = routeId;
            this.formData.routeNa = routeNa;
            this.isAnnular = isAnnular;
        },
        watch: {
            // tab: function(val) {
            //     if (val == 1) {
            //         this.$refs.realRunMap.setShowNum();
            //     }
            // }
        },
        mounted() {
            this.getSiteList();
            this.getRoutePreSetSite();
            this.$refs.realRunMain.getData();
            this.setIntervalObj();
        },
        methods: {
            getType() {
                return this.isAnnular;
            },
            setIntervalObj() {
                console.log(this.isAnnular)
                this.getRuningVehicle();
                this.$refs.realRunMain.getWaitVehicleList();
                this.$refs.realRunMain.getException();
                this.$refs.realRunMain.getLargeIntervalDetail();
                this.$refs.realRunMain.getStringCarDetail();
                if (this.intervalObj) {
                    clearInterval(this.intervalObj);
                    this.intervalObj = null;
                }
                this.intervalObj = setInterval(() => {
                    console.error(this.formData)
                    this.getRuningVehicle();
                    this.$refs.realRunMain.getWaitVehicleList();
                    this.$refs.realRunMain.getException();
                    this.$refs.realRunMain.getLargeIntervalDetail();
                    this.$refs.realRunMain.getStringCarDetail();
                }, 10 * 1000);
            },
            getRoutePreSetSite() {
                let url = "/route/info/queryRoutePreSetSite";
                let params = {
                    routeId: this.formData.routeId
                };
                this.$http.post(url, params).then(({ detail }) => {
                    this.preTrackList = detail;
                });
            },
            getSiteList() {
                let url = "/site/info/queryByRouteId";
                let params = {
                    routeId: this.formData.routeId
                };
                this.$http.post(url, params).then(({ detail }) => {
                    this.siteList.splice(0);
                    if (detail) {
                        let list = detail.map(item => {
                            let obj = {
                                ...item,
                                ...item.routeSite,
                                ...item.siteInfo
                            };
                            let newLngLat = this.$util.wgs84tobd09(Number(obj.lng), Number(obj.lat));
                            obj.lng = newLngLat[0];
                            obj.lat = newLngLat[1];
                            return obj;
                        });
                        this.siteList.push(...list);
                    }
                    console.log(this.siteList)
                });
            },
            getRuningVehicle() {
                let url = "/waybillActual/queryRealtimeVehicleList";
                let params = {
                    ...this.formData
                };
                this.$http.post(url, params).then(({ detail }) => {
                    console.log(detail);
                    let runingVehicleList = detail;
                    this.queryVehiclePosition(runingVehicleList.map(item => item.vehicleId).join(","));
                });
            },
            queryVehiclePosition(ids) {
                let url = "/waybillActual/queryVehiclePosition";
                let params = {
                    vehicleIds: ids
                };
                this.$http.post(url, params).then(({ detail }) => {
                    this.positionVehicleList.splice(0);
                    if (detail) {
                        detail.forEach(item => {
                            let newLngLat = this.$util.wgs84tobd09(Number(item.lng), Number(item.lat));
                            item.lng = newLngLat[0];
                            item.lat = newLngLat[1];
                        });
                        this.positionVehicleList.push(...detail);
                    }
                });
                console.log(this.positionVehicleList)
            }
        }
    };
</script>
<style lang="scss" scoped="real_time">
    @import "@/components/pages/admin/realTime/realTime.scss";
    .real_time{
        height: 100%;
        width: 100%;
        min-height: 260px;
        .route_content {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-top: 20px;
            .tabs {
                display: flex;
                margin-bottom: 20px;
                margin-top: 20px;
                > * {
                    flex: 1;
                    line-height: 40px;
                    font-size: 16px;
                    text-align: center;
                    color: #797979;
                    border: 1px solid $bordercolor;
                    @extend .hand;

                    &:nth-of-type(2) {
                        border-left: 1px solid $bordercolor;
                    }

                    &:hover {
                        color: #323232;
                    }

                    &.alive {
                        color: $rbc;
                        font-weight: bold;
                        border-color: $rbc;
                    }
                }
            }
        }
    }
</style>
